@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --tw-ring-color: @apply #006BA6;
  }
  html {
    scroll-behavior: smooth;
    scroll-padding-top: 100px;
    scroll-padding-bottom: 100px;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
    font-size: 10px;
  }

  body {
    @apply prose-sm sm:prose min-w-full max-w-full w-full min-h-screen mx-auto overflow-x-hidden;
  }
  svg {
    display: inline-block;
  }

  @keyframes react-loading-skeleton {
    100% {
      transform: translateX(100%);
    }
  }

  /* containers */
  .w-container {
    @apply mx-auto w-full max-w-screen-lg px-8 lg:px-0;
  }

  .rte-container {
    margin: 3rem 0;
  }
  .site-container {
    min-height: calc(100vh - 70px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    @media (min-width: 48em) {
      .site-container {
        min-height: 100vh; } 
    }

  /* react loading skeleton */
  .react-loading-skeleton {
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;
    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block;
    /* Enable animation */

    background-color: var(--base-color);

    width: 100%;
    border-radius: 0.25rem;
    display: inline-flex;
    line-height: 1;

    position: relative;
    user-select: none;
    overflow: hidden;
    z-index: 1;
    /* Necessary for overflow: hidden to work correctly in Safari */
  }

  .react-loading-skeleton::after {
    content: " ";
    display: var(--pseudo-element-display);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg,
        var(--base-color),
        var(--highlight-color),
        var(--base-color));
    transform: translateX(-100%);

    animation-name: react-loading-skeleton;
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  .bg-droplet {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138.075' height='258.89'%3E%3Cpath fill='%23f7fafb' d='M18.83 144.817l40.71-77.329L94.292-.001l37.105 68.1 38.081 72.084q.867 1.568 1.667 3.155c5.292 10.582 8.248 21.679 8.248 32.1 0 47.33-37.853 83.254-85.259 83.254S8.686 222.761 8.686 175.635c0-9.7 2.92-20.408 8.145-30.818z' /%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right;
  }

  .bg-droplet-sm {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='165'%3E%3Cpath fill='%23f7fafb' d='M5.191 92.299h-.015l25.946-49.287L54.56 0l23.649 43.4 24.27 45.942q.553 1 1.062 2.011l.066.125a46.446 46.446 0 015.257 20.461c-.004 30.161-24.129 53.061-54.342 53.061S0 141.974 0 111.939c0-6.184 1.86-13.007 5.19-19.64'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right;
  }

  .text-shadow {
    text-shadow: 2px 2px 10px rgba(51, 51, 51, 0.6);
  }

  @media (prefers-reduced-motion) {
    .react-loading-skeleton {
      --pseudo-element-display: none;
      /* Disable animation */
    }
  }

  .h1 {
    @apply text-[42px] leading-[1.2] sm:text-h1 text-ocean-700 font-gelica font-light;
  }

  .h2 {
    @apply text-[32px] leading-[1.2] sm:text-h2 text-secondary font-gelica font-light;
  }

  .h3 {
    @apply text-[28px] leading-[1.2] sm:text-h3 text-ocean-700 font-gelica font-light;
  }

  .h4 {
    @apply text-h4 text-secondary font-gelica font-light;
  }

  .h5 {
    @apply text-h5 text-ocean-700 font-gelica font-light;
  }

  .h6 {
    @apply text-h6 text-black font-bold;
  }

  input[type="password"]::-ms-reveal {
    display: none !important;
  }
}

.link-arrow {
  @apply inline-flex font-body font-filson no-underline hover:underline pl-6 pt-px mt-4;
  line-height: 1.5;
  background: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 10' width='8' height='12' role='presentation' %3E%3Cpath d='M6 5 1.85 9.65c-.21.23-.5.35-.79.35-.25 0-.5-.09-.7-.27-.44-.39-.47-1.06-.08-1.49L3.17 5 .27 1.76C-.12 1.33-.08.66.36.27c.43-.39 1.1-.35 1.49.08L6 5Z' fill='%231c6586' /%3E%3C/svg%3E") left center/8px 12px no-repeat;
}

.btn-outline-arrow,
.btn-mobile_arrow {
  display: inline-flex !important;
  min-height: 50px;
  min-width: 140px;
  margin: 1rem 0;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  padding: 14px 35px 14px 30px;
  color: #6e6e6e;
  border: 1px solid #5e5e5e;
  height: 100%;
  font-weight: 400;
  font-size: 1.6rem;
  text-transform: none;
  text-decoration: none;
  text-wrap: wrap;
  text-align: left;
  background: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 10' width='6' height='10' role='presentation' %3E%3Cpath d='M6 5 1.85 9.65c-.21.23-.5.35-.79.35-.25 0-.5-.09-.7-.27-.44-.39-.47-1.06-.08-1.49L3.17 5 .27 1.76C-.12 1.33-.08.66.36.27c.43-.39 1.1-.35 1.49.08L6 5Z' fill='%235E5E5E' /%3E%3C/svg%3E") right center/40px 12px no-repeat;
}

.btn-outline-arrow:hover,
.btn-outline-arrow:active,
.btn-mobile_arrow:hover,
.btn-mobile_arrow:active {
  color: #1c6586;
  border: 1px solid #1c6586;
  text-decoration: underline;
  background: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 10' width='6' height='10' role='presentation' %3E%3Cpath d='M6 5 1.85 9.65c-.21.23-.5.35-.79.35-.25 0-.5-.09-.7-.27-.44-.39-.47-1.06-.08-1.49L3.17 5 .27 1.76C-.12 1.33-.08.66.36.27c.43-.39 1.1-.35 1.49.08L6 5Z' fill='%231c6586' /%3E%3C/svg%3E") right center/40px 12px no-repeat;
}

@media (min-width: 37.5em) {
  .btn-outline-arrow {
    padding: 15px 40px 13px 40px;
  }
}

@layer components {
  fieldset {
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  fieldset legend {
    color: #333333;
    font-weight: bold;
    margin-bottom: 0.7rem;
    width: 100%;
  }

  a.wc-btn,
  a.wc-btn--outline,
  input.wc-btn--outline,
  a.wc-btn {
    @apply inline-flex justify-center;
  }

  .wc-btn,
  .btn-primary {
    @apply inline-flex items-center justify-center;
    @apply uppercase box-border text-body px-[40px] py-[16px] rounded-full leading-none text-center font-filson cursor-pointer no-underline;
    @apply disabled:cursor-not-allowed whitespace-nowrap;
    @apply text-white bg-river-600 hover:bg-secondary active:bg-ocean disabled:bg-grey-300 border-0;
  }

  .btn-primary-alt {
    @apply inline-flex items-center justify-center;
    @apply uppercase box-border text-body px-[40px] py-[16px] rounded-full leading-none text-center font-filson cursor-pointer no-underline;
    @apply focus:outline-dashed focus:outline-2 focus:outline-white disabled:cursor-not-allowed whitespace-nowrap;
    @apply bg-white text-grey-900 hover:opacity-90 active:opacity-80 disabled:opacity-50 border-0;
  }

  .wc-btn--outline,
  .btn-secondary {
    @apply inline-flex items-center justify-center;
    @apply uppercase box-border text-body px-[38px] py-[14px] rounded-full leading-none text-center font-filson cursor-pointer no-underline;
    @apply disabled:cursor-not-allowed;
    @apply focus:outline-offset-0 focus:border-none focus:px-[40px] focus:py-[16px];
    @apply text-river-600 border-2 border-spacing-0 border-river-600 bg-transparent outline-none whitespace-nowrap;
    @apply hover:text-secondary hover:border-secondary hover:bg-river-100 active:text-ocean-700 active:border-ocean-700 disabled:border-grey-300 disabled:text-grey-300 disabled:bg-transparent;
  }

  .btn-secondary-alt {
    @apply inline-flex items-center justify-center;
    @apply uppercase box-border text-body px-[38px] py-[14px] rounded-full leading-none text-center font-filson cursor-pointer no-underline;
    @apply disabled:cursor-not-allowed;
    @apply focus:outline-offset-0 focus:outline-dashed focus:outline-2 focus:outline-white focus:border-none focus:px-[40px] focus:py-[16px];
    @apply text-white border-2 border-spacing-0 border-white bg-transparent outline-none whitespace-nowrap;
    @apply hover:bg-white/10 active:bg-white/20 disabled:border-grey-100 disabled:text-grey-100 disabled:opacity-50;
  }

  .btn-tertiary {
    @apply inline-flex items-center justify-center;
    @apply uppercase box-border text-body px-[40px] py-[16px] rounded-full leading-none text-center font-filson cursor-pointer;
    @apply disabled:cursor-not-allowed;
    @apply text-river-600 border-none underline decoration-solid bg-white whitespace-nowrap;
    @apply hover:text-secondary hover:border-secondary hover:bg-river-100 hover:no-underline active:text-ocean-700 active:border-ocean-700 disabled:border-grey-300 disabled:text-grey-300 disabled:bg-transparent;
  }

  .btn-tertiary-alt {
    @apply inline-flex items-center justify-center;
    @apply uppercase box-border text-body px-[40px] py-[16px] rounded-full leading-none text-center font-filson cursor-pointer;
    @apply focus:outline-dashed focus:outline-2 focus:outline-white disabled:cursor-not-allowed;
    @apply text-white border-none underline decoration-solid bg-transparent whitespace-nowrap;
    @apply hover:bg-white/10 hover:no-underline active:bg-white/20 disabled:text-grey-300 disabled:bg-transparent disabled:underline;
  }

  .btn-cta {
    @apply inline-flex items-center justify-center;
    @apply uppercase box-border text-body px-[40px] py-[16px] rounded-full leading-none text-center font-filson cursor-pointer no-underline;
    @apply disabled:cursor-not-allowed;
    @apply text-black bg-wattle-200 first:mr-2 border-0 whitespace-nowrap;
    @apply hover:bg-wattle-300 active:bg-wattle-400 disabled:bg-grey-300 disabled:text-white;
  }

  .btn-mobile {
    @apply max-sm:w-full max-sm:justify-center max-sm:mb-6 md:whitespace-normal;
  }

  .btn-full,
  .wc-btn--block {
    @apply flex w-full justify-center mb-4;
  }

  .btn-processing {
    @apply inline-block align-bottom w-full sm:w-auto m-4 min-h-20 leading-8;

    div {
      @apply inline-block align-middle;
    }

    .wc-preloader {
      @apply h-8 w-8 mr-2 mb-0 ml-0 mt-0 sm:mt-6;
    }

    .wc-preloader__circle {
      width: 8px;
      height: 8px;
    }

    .processing-message {
      @apply w-4/5 sm:w-auto sm:mt-6;
    }
  }

  .link {
    @apply inline-flex items-center justify-center;
    @apply cursor-pointer text-river-600 font-normal underline;
  }

  .link:hover,
  a:hover {
    @apply no-underline;
  }

  .label-primary {
    @apply block font-body text-grey-700 font-bold mb-2;
  }

  .label-secondary {
    @apply block font-body text-grey-600 mb-6;
  }

  .label-assist {
    @apply text-small text-grey-600;
  }
  .field-validation-error {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 31.854'%3E%3Cpath fill='%23a20017' d='M35.654 28.023 27.932 14.65 20.21 1.277a2.553 2.553 0 0 0-4.424 0L8.066 14.65.344 28.023a2.555 2.555 0 0 0 2.213 3.831H33.44a2.555 2.555 0 0 0 2.214-3.831Zm-17.679-19.4a1.556 1.556 0 0 1 1.656 1.405l-.341 11.154a1.235 1.235 0 0 1-1.317 1.121 1.26 1.26 0 0 1-1.341-1.121l-.337-11.154a1.581 1.581 0 0 1 1.681-1.406Zm.028 18.623h-.028a1.85 1.85 0 0 1-1.826-1.874 1.848 1.848 0 0 1 1.874-1.826 1.847 1.847 0 0 1 1.826 1.826A1.86 1.86 0 0 1 18 27.245Z'/%3E%3C/svg%3E");
    background-size: 18px 18px;
    background-position: 0;
    @apply bg-no-repeat text-validation-red text-small pl-12;
  }



  .form-input {
    @apply text-input text-grey-900;
    @apply placeholder:italic placeholder:text-grey-400;
  }


  .input-base {
    @apply border border-grey-700 rounded-lg p-6 form-input outline-offset-1 w-full text-grey-900;
    @apply placeholder:italic placeholder:text-grey-400;
    @apply aria-[invalid=true]:border-validation-red aria-[invalid=true]:border-2;
    @apply disabled:bg-grey-100 disabled:border-0 disabled:focus:outline-none disabled:cursor-not-allowed;
    @apply read-only:bg-grey-100 read-only:border-0 read-only:focus:outline-none read-only:cursor-text;
  }

  .input-search,
  .input-clock,
  .input-calendar,
  .input-currency,
  .input-primary {
    @apply input-base;
  }

  .input-icon {
    @apply bg-no-repeat bg-[length:18px_18px] bg-[right_15px_center] pr-[45px];
  }

  .input-search,
  .input-clock,
  .input-calendar,
  .input-primary {
    @apply input-icon;
  }

  .input-search {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20.033 20.502' fill='%236e6e6e'%3E%3Cpath d='M18.788 20.502a1.235 1.235 0 01-.9-.384l-4.813-5.019a1.245 1.245 0 01.041-1.762 6.235 6.235 0 001.953-4.557 6.29 6.29 0 10-6.29 6.289 1.246 1.246 0 110 2.492 8.78 8.78 0 118.78-8.78 8.7 8.7 0 01-1.882 5.435l4.007 4.181a1.245 1.245 0 01-.9 2.106z'/%3E%3C/svg%3E");
  }

  .input-clock {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 35' fill='%230070a9'%3E%3Cpath d='M16.108 20.135a1.391 1.391 0 01-1.391-1.391V8.351a1.391 1.391 0 112.782 0v10.393a1.391 1.391 0 01-1.391 1.391z'/%3E%3Cpath d='M22.082 24.292a1.383 1.383 0 01-.8-.255l-5.769-4.08a1.391 1.391 0 011.606-2.271l5.769 4.08a1.391 1.391 0 01-.8 2.526z'/%3E%3Cpath d='M17.5 35A17.5 17.5 0 1135 17.5 17.52 17.52 0 0117.5 35zm0-32.218A14.718 14.718 0 1032.218 17.5 14.735 14.735 0 0017.5 2.782z'/%3E%3C/svg%3E");
  }

  .input-calendar {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.757 20.841' fill='%238c8c8c'%3E%3Cpath d='M16.673 2.084h-1.042V0h-2.084v2.084H5.21V0H3.126v2.084H2.084A2.075 2.075 0 00.01 4.168L0 18.757a2.084 2.084 0 002.084 2.084h14.589a2.09 2.09 0 002.084-2.084V4.168a2.09 2.09 0 00-2.084-2.084zm0 16.673H2.084V7.294h14.589zm-12.5-9.379h5.21v5.21H4.168z'/%3E%3C/svg%3E");
    background-size: 16px 18px;
  }

  .input-currency {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 511.995 511.995'%3E%3Cpath d='M278.622 224.006V110.243a151.685 151.685 0 0166.901 29.087 23.943 23.943 0 0013.251 4.201c15.222 0 27.633-12.249 27.794-27.471a24.674 24.674 0 00-7.11-17.452 170.544 170.544 0 00-101.159-40.722V19.749c0-10.892-8.823-19.715-19.715-19.715-.226-.032-.453-.032-.679-.032-11.053-.162-20.167 8.662-20.329 19.747v36.844c-72.718 5.171-122.166 50.095-122.166 109.885 0 73.364 62.376 93.726 122.166 109.885V405.64c-31.544-4.234-61.277-17.323-85.646-37.813-4.589-3.652-10.278-5.688-16.16-5.817-14.899 1.034-26.372 13.509-26.179 28.441a24.674 24.674 0 007.11 17.452 190.344 190.344 0 00121.197 48.802v35.551c0 .226.032.453.032.679.517 11.053 9.922 19.585 20.975 19.036 10.892 0 19.715-8.823 19.715-19.715v-36.197c88.231-5.817 123.782-59.467 123.782-116.349.002-76.274-63.989-99.544-123.78-115.704zM237.9 213.664c-35.228-10.342-62.699-21.007-62.699-51.064s24.886-51.711 62.699-54.619v105.683zm40.722 192.622V288.645c36.521 10.342 64.961 24.239 64.638 58.174 0 24.563-16.806 53.65-64.638 59.467z'/%3E%3C/svg%3E");
    @apply bg-left bg-[length:16px_14px] bg-no-repeat pl-12;
  }


  .form-item {
    @apply my-12 max-w-[463px] font-body text-body;
  }

  .validate {
    @apply valid:border-validation-green valid:border-2 invalid:border-validation-red invalid:border-2;
  }

  .input-validation-success,
  .valid {
    @apply border-validation-green border-2;
  }

  .input-validation-error,
  .invalid {
    @apply border-validation-red border-2;
  }
  .checkbase {
    @apply appearance-none box-border inline-block align-middle h-9 w-9 p-0 ml-2 mr-4 shrink-0 select-none text-river-600 bg-white border border-grey-500 outline-offset-2;
  }
  .check-primary {
    @apply checkbase rounded-md bg-contain bg-center checked:bg-no-repeat checked:bg-secondary;
    @apply aria-[invalid=true]:border-validation-red aria-[invalid=true]:border-2;
  }

  .check-primary:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }

  .radio-primary {
    @apply checkbase rounded-full checked:bg-no-repeat checked:bg-secondary;
  }

  .radio-primary:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    background-position: 50%;
    background-size: 100% 100%;
  }

  .check-primary:checked+span,.radio-primary:checked+span {
    @apply text-river-600;
  }

  .check-primary {
    @apply appearance-none box-border inline-block align-middle h-9 w-9 p-0 mr-4 shrink-0 select-none text-river-600 bg-white rounded-md border border-grey-500 outline-offset-2;
    @apply bg-contain bg-center checked:bg-no-repeat checked:bg-river-600;
  }

  .check-primary:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }

  .check-primary:checked+span {
    @apply text-river-600;
  }

  .radio-primary {
    @apply appearance-none box-border inline-block align-middle h-9 w-9 p-0 mr-4 shrink-0 select-none text-river-600 bg-white rounded-full border border-grey-500 outline-offset-2;
    @apply checked:bg-no-repeat checked:bg-river-600;
  }

  .radio-primary:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    background-position: 50%;
    background-size: 100% 100%;
  }

  .radio-primary:checked+span {
    @apply text-river-600;
  }

  .menu-item {
    @apply inline-flex items-center justify-center;
    @apply font-filson text-h7 text-grey-900 font-normal;
    @apply no-underline hover:underline;
    @apply disabled:cursor-not-allowed;
    @apply outline-offset-0;
  }

  .menu-item.active {
    @apply text-river-600 !font-medium;
  }

  .menu-item.active.plus {
    @apply underline hover:no-underline decoration-5 underline-offset-9;
  }

  .category-label {
    @apply font-filson text-small text-coastal-500;
  }

  .topic-tag {
    @apply inline-flex uppercase font-filson text-xs text-white bg-coastal-500 !p-[10px] rounded-[4px] leading-none m-0;
  }

  ul.list-none {
    padding: 0;
    margin: 0;

    li {
      margin: 0;
      padding-left: 0;

      a.menu-item {
        @apply py-4;
      }
    }
  }
}

@layer utilities {
  .focus-outline {
    @apply focus:outline-dashed focus:outline-2 focus:outline-ocean;
  }

  *:focus {
    @apply focus-outline;
  }

  .skip-to-content {
    @apply absolute -translate-y-96 transition-transform top-8 left-8;
  }

  .skip-to-content:focus {
    @apply translate-y-0;
  }
}